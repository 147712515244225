export const phrases = {
  your_party: "A tua festa.",
  all_pictures: "Todas as fotografias.",
  forgot_password: "Esqueceste-te da tua palavra-passe?",
  no_account_yet: "Ainda não tens uma conta? Regista-te agora.",
  send_your_event: "Convida os teus convidados",
  scan_here: "Digitaliza aqui",
  show_more_replies: "vê mais respostas",
  shared_image_text: "Vê esta fotografia tirada num evento com https://www.everlense.de",
  image_not_available_anymore: "Esta imagem já não está disponível",
  add_images: "Adiciona imagens",
  capture_photo: "Tira uma fotografia",
  enter_your_name: "Introduz o teu nome",
  your_photo_app_for_weddings: "A tua aplicação fotográfica para casamentos",
  invite_guests: "Convida os convidados",
  delete_demo_data: "Eliminar dados de demonstração",
  event_description_placeholder: "Que mais gostarias de dizer aos teus convidados? Vai às definições para personalizar a descrição.",
  start_slideshow: "Inicia a apresentação de slides aqui",
  slideshow_steps_1: "Vai para com o teu dispositivo",
  slideshow_steps_2: "e introduz o seguinte",
  slideshow_steps_3: "Código de 6 dígitos",
  slideshow_steps_4: "em.",
  print_tasks: "Tarefas de impressão",
  edit_tasks: "Gerir tarefas",
  challenge_tips_and_tricks_1: "Dicas e truques para",
  challenge_tips_and_tricks_2: "O teu desafio perfeito",
  challenge_tips_and_tricks_content_1: "Imprime o teu modelo em papel grosso.",
  challenge_tips_and_tricks_content_2: "Distribui as tarefas por cada lugar antes da tua festa.",
  challenge_tips_and_tricks_content_3: "Informa o maior número possível de pessoas sobre o desafio fotográfico.",
  challenge_tips_and_tricks_content_4: "Utiliza a apresentação de diapositivos em direto para que todos os convidados possam ver as fotografias em tempo real.",
  print_tasks_intro: "Deves expô-los na festa para que os convidados possam encontrar as tarefas do desafio fotográfico.",
  print_tasks_title_1: "Cartões para imprimir (populares)",
  print_tasks_title_2: "Exporta apenas códigos QR (e desenha tu mesmo os cartões)",
  print_tasks_action_1: "Descarrega PDFs",
  print_tasks_action_2: "Descarregar códigos QR",
  print_tasks_description_1: "Os teus convidados podem participar no desafio fotográfico utilizando os códigos QR nos nossos cartões para impressão.",
  print_tasks_description_2: "Transfere códigos QR para iniciares o desafio fotográfico e utiliza-os para criares os teus próprios cartões. Há um código QR por tarefa.",
  edit_tasks_title_1: "Selecciona as tarefas",
  edit_tasks_title_2: "para os teus convidados",
  really_want_to_delete_task: "Apaga mesmo a tarefa?",
  add_task_button: "Adicionar tarefa",
  delete_all_tasks_button: "Apaga tudo",
  settings_event_name_header: "Como se deve chamar o teu evento?",
  settings_event_description_header: "Que mais é que os teus convidados precisam de saber?",
  settings_event_name_label: "Nome do evento",
  settings_event_description_label: "Descrição do evento",
  settings_password_title: "Altera a palavra-passe",
  settings_old_password_header: "Palavra-passe antiga",
  settings_new_password_header: "Nova palavra-passe",
  settings_new_password_repeat_header: "Repete a nova palavra-passe",
  settings_invite_admin_title: "Adicionar administradores",
  settings_invite_admin_header: "Endereço de correio eletrónico do novo administrador",
  send_invite: "Enviar convite",
  settings_deactivate_challenge_title: "Desativar desafio",
  settings_deactivate_challenge_header: "Ativar/desativar o desafio",
  settings_delete_data_title: "Eliminar a conta",
  settings_delete_data_header: "Elimina permanentemente todos os dados",
  delete_event: "Elimina o evento",
  delete_event_toast_content: "Clica em \"Apagar\" para fechar a tua conta permanentemente. ATENÇÃO: Todas as imagens, vídeos e dados carregados são então irrevogavelmente perdidos e não podem mais ser restaurados, mesmo pela equipa everlense.",
  settings_legal: "Assuntos jurídicos",
  settings_legal_content_1: "Com a criação do teu evento, tens",
  settings_legal_content_2: "GTC",
  settings_legal_content_3: " e o nosso",
  settings_legal_content_4: "Política de privacidade ",
  settings_legal_content_5: "aceites. Se quiseres saber mais sobre a everlense, visita a nossa ",
  settings_legal_content_6: "Imprime.",
  reset_password: "Repor a palavra-passe",
  register_phrase: "Precisamos do teu endereço de e-mail para finalizar a organização do teu evento.",
  register_phrase_booked: "A reserva foi efectuada com sucesso! Precisamos do teu endereço de e-mail para finalizar a organização do teu evento.",
  email_address: "Endereço de correio eletrónico",
  register_legal: "Ao aderir, aceita os nossos <a class=\"underline\" href=\"https://www.everlense.de/agb\">termos e condições</a> e a nossa <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">política de privacidade</a>. Se quiseres saber mais sobre a everlense, visita o nosso <a class=\"underline\" href=\"https://www.everlense.de/impressum\">aviso legal</a>. Ao inscreveres-te, também concordas em receber e-mails de marketing da nossa parte. Podes revogar este consentimento em qualquer altura.",
  install_everlense: "Instalar o everlense",
  install_content_1: "Instala o everlense no teu ecrã inicial para nunca esqueceres o evento.",
  install_content_2: "Clica em",
  install_content_3: "e, em seguida, \"Ir para o ecrã inicial\".",
  install_content_4: "Mais tarde",
  install_content_5: "Não te preocupes!",
  no_images_yet: "Ainda não foram carregadas quaisquer imagens. Carrega no botão mais para carregar uma imagem.",
  delete_all_tasks_really: "Apaga mesmo todas as tarefas?",
  your_task: "A tua tarefa",
  change_colors: "Altera as cores",
  primary_color: "Cor primária",
  background_color: "Cor de fundo",
  reset: "Reiniciar",
  choose_color: "Selecciona a cor",
  upgrade_package: "Pacote de atualização",
  upgrade_required: "Atualização necessária",
  current_package: "Pacote atual",
  used_image_count: "{used} de {max} imagens.",
  deleted_image_count: "{not_deleted} atualmente na tua galeria, {deleted} eliminado.",
  used_downloads_count: "{used} utilizado por {max} transferências em massa.",
  most_popular: "Mais populares",
  price: '{price} Euro',
  max_media: "máx. {count} imagens",
  max_guests: "máx. {count} Convidados",
  infinite_guests: "Número ilimitado de convidados",
  storage_duration: "{duration} Meses Período de armazenamento",
  max_bulk_downloads: "download gratuito em massa | downloads gratuitos em massa",
  custom_colors: "Cores individuais",
  multiple_admins: "Vários administradores",
  whatsapp_support: "Suporte Premium para WhatsApp",
  go_to_checkout: "Para a caixa",
  start_setup: "Inicia a configuração",
  upgrade_toast_content: "Para poderes utilizar esta função, tens de atualizar o teu pacote everlense. Dá uma vista de olhos às várias opções. ",
  no_thank_you: "Não, obrigado.",
  i_want_to_know_more: "Aprende mais",
  demo_event_title: "Lisa e Jan",
  how_do_you_want_to_reedem: "Como gostarias de resgatar a tua encomenda?",
  currently_logged_in_event: "Estás atualmente ligado ao evento: {name} Gostarias de aplicar a tua compra a este evento?",
  choose_event: "A que evento gostarias de aplicar a tua compra?",
  use_for_current_event: "acontecimento atual",
  use_for_existing_event: "evento existente",
  use_for_new_event: "novo evento",
  settings_event_date_header: "Quando se realiza o teu evento?",
  settings_event_date_label: "Data do evento",
  sort: "Selecciona",
  time_of_recording: "Tempo de gravação",
  time_of_upload: "Tempo de carregamento",
  lets_setup_event: "Vamos organizar o teu evento juntos",
  we_will_help_you_setup: "Ajudamos-te a organizar o teu evento everlense em apenas 2 minutos. Apetece-te?",
  what_is_the_name: "Qual deve ser o nome do teu evento?",
  cancel_setup: "Cancelar configuração",
  enter_event_name_here: "Introduz o nome do evento aqui",
  when_does_event_take_place: "Quando se realiza o teu evento?",
  enter_event_date_here: "Introduz aqui a data do evento",
  nice_please_upload_photo: "És muito simpático. Carrega agora uma fotografia do evento para o tornar ainda mais personalizado",
  select_photo: "Selecionar foto",
  what_do_you_want_to_describe: "Ena! Gostarias de dizer mais alguma coisa aos teus convidados?",
  enter_description_here: "Introduz a descrição aqui",
  finish_setup: "Finaliza a configuração",
  successfull_save: "Guardado com sucesso",
  successfull_deletion: "Eliminado com sucesso",
  copied_link: "Link copiado",
  booking_successfull: "Reserva com sucesso",
  completed_setup: "Instalação concluída",
  sent_invite: "Convite enviado",
  initiated_payout: "Pagamento criado",
  sent_email: "E-mail enviado",
  max_ten_images: "Máximo de 10 imagens de uma só vez.",
  reached_download_limit: "Atingiste o limite de descargas do evento.",
  reached_mass_download_limit: "Atinge o número máximo de descargas em massa.",
  no_images_were_uploaded: "Ainda não carregaste nenhuma imagem.",
  booking_failed: "A reserva falhou.",
  package_already_retrieved: "Pacote já resgatado",
  download_all_pictures: "Transfere todas as imagens",
  notice: "Sugestão",
  in_your_package: "Na tua encomenda",
  bulk_download_modal_2: "de todas as fotos de uma só vez. Se clicares agora em \"Transferir\", vais consumir",
  bulk_download_modal_3: "um destes",
  bulk_download_modal_4: "este",
  bulk_download_modal_5: "descarrega",
  bulk_download_modal_6: "Utilizaste todos os downloads em massa do teu pacote. Reserva uma atualização para transferir todas as imagens.",
  bulk_download_modal_7: "Aguarda. As tuas imagens estão a ser preparadas para transferência.",
  donwload_modal_1: '',
  donwload_modal_2: "descarrega mesmo?",
  delete_modal_1: '',
  delete_modal_2: "apaga mesmo?",
  download_preparation: "O teu download está a ser preparado. Receberás uma mensagem de correio eletrónico com a tua ligação pessoal de transferência dentro de 15 minutos, o mais tardar.",
  save_pictures: "Guardar imagens",
  delete_pictures: "Eliminar imagens",
  upload_in_progress: "Carrega em execução",
  change_language: "Altera a língua",
  change_language_info: "Os teus convidados podem utilizar o everlense em inglês e alemão. O idioma adapta-se automaticamente ao dispositivo do hóspede.",
  multiple_languages_1: "Multilinguismo",
  multiple_languages_2: "(Inglês e Alemão)",
  okay: "Não te preocupes!",
  download_pictures: "Descarrega imagens",
  max_ten_images_go_to_settings: "Permite um máximo de 10 imagens. Vai às definições para transferir todas as imagens.",
  your_images_are_ready: "Viva, as tuas fotografias estão prontas!",
  here_are_your_images: "Aqui estão as fotos do teu evento {event}. Diverte-te!",
  ps_mass_download: "PS: Descarrega simplesmente as partes uma após a outra. Isto só será contabilizado como uma transferência em massa.",
  download_here: "Descarrega aqui",
  download_part: "Descarrega a peça {part}",
  switch_language: "Altera a língua",
  tasks_choose_language: "Em que língua deves escrever as tarefas de amostra?",
  successfull_task_recreation: "A língua das tarefas de exemplo foi alterada",
  something_went_wrong: "Algo correu mal",
  a_small_present_for_you: "Um pequeno presente para ti!",
  present_description: "Gostarias de utilizar o everlense no teu evento? <span class=\"font-medium\">Então temos uma surpresa para ti</span>. Enviá-lo-emos para o teu endereço de e-mail.",
  your_email_address: "O teu endereço de correio eletrónico",
  save_discount: "Envio",
  accept_agb_and_mail: "Concordo com os <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">termos e condições</a> e gostaria de receber e-mails da everlense.",
  thank_you_for_sending: "Enviar-te-emos um e-mail com a tua surpresa personalizada nos próximos 15 minutos.",
  discount_saved: "Surpresa garantida!",
  was_reported: "{type} Um hóspede relatou isto .",
  what_shall_happen: "{type} O que é que acontece com isto?",
  edit_reporting_description: "{type} Por favor, verifica e decide se deve ser libertada novamente ou eliminada permanentemente. Entretanto, ocultámos a imagem.",
  how_did_you_get_to_know_us: "Como é que soubeste do everlense?",
  please_select: "Por favor, selecciona",
  friends_and_family: "Amigos e conhecidos",
  seen_at_a_wedding: "Visto num casamento",
  other: "Diversos"
};